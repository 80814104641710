<script>
    import blockUI from './BlockUIService.js';
    import moment from 'moment';
    import DataService from './DataService.js';
    import CryptoJS from 'crypto-js';
    export default {
        data() {
            return {
                loginDetails: '',

                AcademicLevels: [],
                AcademicSpecializations: [],
                Subjects: [],
                Instructors: [],
                PaymentMethods: [],

                Shapters: [],
                Courses: [],
                Users: [],
                Distributors: [],





                //Cities: [],
                //Municipalities: [],
                //Locations: [],
                
                //PaymentMethods: [],
                //Courses: [],
                //FromCourses: [],
                //ToCourses: [],


                //Distributors: [],

                /*Publeish*/
                //ServerUrl: 'https://dashboard.platform.traneem.ly',

                /*Local*/
                ServerUrl: 'https://evospace.traneem.ly',
                Facebock: 'https://www.facebook.com/p/Traneem-100064940462078/?locale=ar_AR',
                Instagram: 'https://www.instagram.com/traneem5__/',
                TraneemPhone: '+218 94 457 81 48',
                TraneemEmail: 'info@traneem.ly',
                PlatFormPass: 'Traneem!@Platformv1',


                WebSiteName: 'Evo Space',
                BuildFor: 'Evo Space',
                logoPath: '/assets/img/1/3.png',
                CoverPath: '/assets/img/illustrations/auth-register-illustration-dark.png',
                PdfPath: '/assets/img/icons/misc/pdf1.png',

            }
        },
        methods: {

            encrypt: function encrypt(data, SECRET_KEY) {
                var dataSet = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);
                dataSet = dataSet.toString();
                return dataSet;
            },
            decrypt: function decrypt(data, SECRET_KEY) {
                data = CryptoJS.AES.decrypt(data, SECRET_KEY);
                data = JSON.parse(data.toString(CryptoJS.enc.Utf8));
                return data;
            },

            ClearForm(ruleForm) {
                for (const key in ruleForm) {
                    ruleForm[key] = ''; // Reset to an empty string
                }
            },

            async CheckLoginStatus() {
                try {
                    this.loginDetails = JSON.parse(this.decrypt(localStorage.getItem('currentUser-client'), this.PlatFormPass));
                    const res = await DataService.IsLoggedin();
                    if (!res.data)
                        this.logout();
                } catch (error) {
                    this.logout();
                }
            },

            async logout() {
                localStorage.removeItem('currentUser-client');
                localStorage.clear();
                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                
                this.$http.Logout()
                    .then(() => {
                        window.location.href = "/Login";
                    })

                window.location.href = "/Login";
            },


            formatNumber(num) {
                if (num) {
                    if (num <= 0) {
                        return 0;
                    } else {
                        return new Intl.NumberFormat('en-US').format(num); // Format the number
                    }
                } else {
                    return 0;
                }
            },

            moment(date) {
                if (date === null) {
                    return "------";
                }
                // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
                return moment(date).format('DD/MM/YYYY');
            },

            momentTime (date) {
                if (date === null) {
                    return "------";
                }
                // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
                return moment(date).format('DD/MM/YYYY ||  h:mm a');
            },

            ChangeDate(date) {
                    if (date === null) {
                        return "فارغ";
                    }
                    return moment(date).format("YYYY-MM-DD");
            },




            //Get Dictionaries
            async GetAllAcademicLevels() {
                this.AcademicLevels = [],
                blockUI.Start();
                try {
                    const res = await DataService.GetAllAcademicLevels();
                    this.AcademicLevels = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllAcademicSpecializations(Id) {
                this.AcademicSpecializations = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllAcademicSpecializations(Id);
                    this.AcademicSpecializations = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllSubjects() {
                this.Subjects = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllSubjects();
                    this.Subjects = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllInstructors() {
                this.Instructors = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllInstructors();
                    this.Instructors = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllPaymentMethods() {
                this.PaymentMethods = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllPaymentMethods();
                    this.PaymentMethods = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },

            async GetAllShapters(Id) {
                this.Shapters = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllShapters(Id);
                    this.Shapters = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },


            async GetAllCourses(SubjectId, AcademicSpecializationId) {
                this.Courses = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllCoursesBySubjectSpecialization(SubjectId, AcademicSpecializationId);
                    this.Courses = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },



            async GetAllUsers() {
                this.Users = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllUsers();
                    this.Users = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },


            async GetAllDistributors() {
                this.Distributors = [],
                    blockUI.Start();
                try {
                    const res = await DataService.GetAllDistributors();
                    this.Distributors = res.data.info;
                    blockUI.Stop();
                } catch (err) {
                    blockUI.Stop();
                }
            },






           
        }
    }
</script>
